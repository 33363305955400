<template>
  <div>
    <div class="caption text-right text--secondary mt-6">
      {{ dictIndicationsDescription }}
    </div>
    <div v-if="isIndicationsEditor" class="d-flex justify-end pa-3">
      <v-switch
        v-model="editMode"
        label="Редактировать"
        hide-details
        class="my-0"
      />
    </div>
    <MedicineIndicationsTable
      readonly
      :edit-mode="isIndicationsEditor && editMode"
    />
  </div>
</template>

<script>
import MedicineIndicationsTable from '@/components/dict/indications/MedicineIndicationsTable'
import { mapGetters } from 'vuex'
import UserActions from '@/components/viewLogs/userActions'
export default {
  name: 'ReferencesIndications',
  components: { MedicineIndicationsTable },
  data() {
    return {
      editMode: false,
    }
  },
  computed: {
    ...mapGetters(['dictIndicationsDescription', 'isIndicationsEditor']),
  },
  created() {
    this.editMode =
      this.isIndicationsEditor &&
      (JSON.parse(localStorage.getItem('dictIndicationsEditMode')) ?? false)
  },
  watch: {
    editMode(newVal) {
      localStorage.setItem('dictIndicationsEditMode', JSON.stringify(newVal))
    },
  },
}
</script>

<style scoped></style>
